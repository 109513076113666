import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { BehaviorSubject, Observable, catchError, from, map, of } from 'rxjs';
import { UserDto } from '../../core/users/dto/dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn: BehaviorSubject<string>;
  public user: UserDto;

  constructor(private router: Router) {
    this.loggedIn = new BehaviorSubject<string>('');
  }

  /** get authenticat state */
  public isAuthenticated(): Observable<string> {
    return from(getCurrentUser()).pipe(
      map((result) => {
        this.loggedIn.next(result.signInDetails.loginId);
        return result.signInDetails.loginId;
      }),
      catchError((error) => {
        this.loggedIn.next('');
        this.signOut();
        return of('');
      })
    );
  }
  public signOut() {
    from(signOut()).subscribe(
      (result) => {
        this.loggedIn.next('');
        this.router.navigate(['/sign-in']);
      },
      (error) => console.log(error)
    );
  }
}
